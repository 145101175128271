<template>
  <v-app>
    <core-app-bar />

    <core-drawer />

    <core-snackbar />

    <core-view />
  </v-app>
</template>

<script>
import "@/assets/css/main.css";
export default {
  components: {
    CoreAppBar: () => import("@/components/core/CoreAppBar"),
    CoreView: () => import("@/components/core/CoreView"),
    CoreDrawer: () => import("@/components/core/CoreDrawer"),
    CoreSnackbar: () => import("@/components/core/CoreSnackbar"),
  },

  data: () => ({}),

  created() {
    const isDarkMode = localStorage.getItem("themeMode") === "dark";

    this.$vuetify.theme.dark = isDarkMode;
  },

  watch: {
    "$vuetify.theme.dark": function (isDarkMode) {
      localStorage.setItem("themeMode", isDarkMode ? "dark" : "light");
    },
  },
};
</script>

<style scoped></style>
